const products = [
  { id: 19, name: 'Dot', price: 150, description: '30*30*1.5cm', materials: 'Acrylic, gesso-textures, mixed. On canvas' },
  { id: 20, name: 'Very Cold', price: 120, description: '1 - 50*40*1.5cm/2 - 20*50*1.5 cm', materials: 'Gesso-textures, mixed on canvas' },
  { id: 21, name: 'Integration of the Soul', price: 360, description: '50*40cm', materials: 'Acrylic, gesso-textures, mixed. On canvas' },
  { id: 22, name: 'Thoughts', price: 140, description: '30"24*1.5cm', materials: 'Acrylic, gesso-textures, mixed. On canvas' },
  { id: 23, name: 'Indian summer', price: 725, description: '50*70*1.5cm', materials: 'Acrylic, gesso textures,-mixed. On canvas' },
  { id: 24, name: 'The beginning of infinity', price: 260, description: '40*30*1.5cm', materials: 'Acrylic, gesso textures,-mixed. On canvas' },
  { id: 25, name: 'Dance of the fairies', price: 130, description: '30*24*1.5cm', materials: 'Acrylic, gesso textures,-mixed. On canvas' },
  { id: 26, name: 'At the bottom', price: 600, description: '50*60cm', materials: 'Acrylic, gesso textures,-mixed. On canvas' },
  { id: 27, name: 'Objective', price: 340, description: '40*40*1.5cm', materials: 'Acrylic, gesso textures,-mixed. On canvas' },
  { id: 28, name: 'Up', price: 610, description: '50*60*1.5cm', materials: 'Acrylic, gesso textures,-mixed. On canvas' },
  { id: 29, name: 'From afar', price: 740, description: '50*70 *1.5cm', materials: 'Acrylic, gesso textures,-mixed. On canvas' },
  { id: 30, name: 'Vision', price: 350, description: '50*40cm', materials: 'Acrylic on canvas' },
  { id: 31, name: 'The ocean', price: 730, description: '50*70cm', materials: 'Acrylic on canvas' },
  { id: 32, name: 'Creation of a planet', price: 500, description: '30*40cm - +  30*40cm', materials: 'Acrylic on canvas' },
  { id: 33, name: 'Hope', price: 240, description: '32*41cm', materials: 'Acrylic on canvas' },
  { id: 34, name: 'Tree', price: 140, description: '24*30cm', materials: 'Acrylic on canvas' },
  { id: 35, name: 'Birth', price: 250, description: '50*60cm', materials: 'Acrylic on canvas' },
  { id: 36, name: 'Untitled 4', price: 120, description: 'No description available', materials: 'Acrylic on canvas' },
  { id: 37, name: 'Untitled 5', price: 120, description: 'No description available', materials: 'Oil on canvas' },
  { id: 38, name: 'Success', price: 360, description: '50*40*1.5cm / separated: 20*50*1.5cm', materials: 'Gesso textures, mixed on canvas' },
  { id: 39, name: 'Untitled 6', price: 120, description: 'No description available', materials: 'Mixed media on canvas' },
  { id: 40, name: 'Before the tsunami', price: 120, description: 'No description available', materials: 'Acrylic, gesso textures, mixed media. On canvas, Triptych' },
  { id: 41, name: 'Untitled 8', price: 120, description: 'No description available', materials: 'Acrylic on canvas' },
  { id: 42, name: 'Untitled 9', price: 120, description: 'No description available', materials: 'Gesso on canvas' },
  { id: 43, name: 'Untitled 10', price: 120, description: 'No description available', materials: 'Mixed media on canvas' },
  { id: 44, name: 'Untitled 11', price: 120, description: 'No description available', materials: 'Oil on canvas' }
];

export default products;
